import React from 'react';
import styled from 'styled-components';
import ContactInfo from './ContactInfo';
import Copyright from './Copyright';
import SocialMedia from './SocialMedia';
import MailchimpForm from './MailchimpForm';

export default function Footer() {
  return (
    <FooterSection>
      <div className='section-inner'>
        <FooterSectionInner>
          <FooterSectionInnerLeft>
            <MailchimpForm />
          </FooterSectionInnerLeft>
          <FooterSectionInnerRight>
            <ContactInfo />
            <SocialMedia />
          </FooterSectionInnerRight>
        </FooterSectionInner>
        <Copyright />
      </div>
    </FooterSection>
  );
}

const FooterSection = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: #000;
  color: #fff;
  padding: 6.25rem 0 1.75rem 0;
  .footer__headings {
    font-size: 1.57rem;
    font-weight: 700;
  }
`;

const FooterSectionInner = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding-bottom: 6.25rem;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    gap: 3.125rem;
  }
`;

const FooterSectionInnerLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-right: 1px solid white;
  padding-right: 3.625rem;
  gap: 1.313rem;
  @media screen and (max-width: 960px) {
    padding-right: 0;
    border-right: 0;
  }
`;

const FooterSectionInnerRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.313rem;
  width: 100%;
  padding-left: 3.625rem;
  @media screen and (max-width: 960px) {
    padding-left: 0;
  }
`;
