import React from 'react';
import { CookieNotice } from 'gatsby-cookie-notice';

export default function CookieBanner() {
  return (
    <CookieNotice
      backgroundWrapperClasses={'bg-cookie'}
      backgroundClasses={'container-cookie'}
      buttonWrapperClasses={'btn-wrap'}
      acceptButtonClasses={'btn'}
      declineButtonClass={'btn'}
      personalizeValidationClasses={'btn'}
      wrapperClasses={'cookie-notice'}
      cookieMapClasses={'cookie-list-item'}
      personalizeButtonClasses={'btn'}
      personalizeButtonText={'Επιλογές'}
      declineButtonText={'Δεν αποδέχομαι'}
      acceptButtonText={'Αποδέχομαι'}
      personalizeValidationText={'Αποθήκευση'}
      cookies={[
        {
          name: 'necessary',
          editable: false,
          default: true,
          title: 'Απαραίτητα',
          text: 'Τα απαραίτητα cookies είναι απαραίτητα για την σωστή λειτουργία της ιστοσελίδας.',
        },
        {
          name: 'gatsby-gdpr-google-analytics',
          editable: true,
          default: true,
          title: 'Google Analytics',
          text: 'Το Google Analytics είναι ένα στατιστικό εργαλείο της Google που μας επιτρέπει να μετράμε την επισκεψιμότητα της ιστοσελίδας καθώς και την συμπεριφορά των επισκεπτών.',
        },
      ]}
    >
      <h4>Αυτή η ιστοσελίδα χρησιμοποιεί Cookies</h4>
      <p>
        Χρησιμοποιούμε cookies τα οποία είναι απαραίτητα για την λειτουργία της
        ιστοσελίδας μας και για να σας προσφέρουμε μια καλύτερη εμπειρία χρήσης
        της. Επίσης χρησιμοποιούμε προαιρετικά cookies στατιστικού σκοπού που
        μας βοηθούν στην βελτίωση της ιστοσελίδας μας. Δεν αποθηκεύεται τίποτα
        αν δεν αποδεχτείτε τα προαιρετικά cookies.
      </p>
    </CookieNotice>
  );
}
