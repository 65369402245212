import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { FaLongArrowAltRight } from 'react-icons/fa';

export default function MailchimpForm() {
  const [email, setEmail] = useState('');
  const [isPending, setIsPending] = useState(false);

  const handleSubmit = (e) => {
    setIsPending(true);
    e.preventDefault();
    addToMailchimp(email).then((data) => {
      setIsPending(false);
      if (data.result === 'success') {
        toast.success('Εγγραφήκατε στο newsletter της Overquality!');
      } else {
        toast.error('Έγινε κάποιο σφάλμα ή έχετε κάνει ήδη εγγραφή.');
      }
    });
  };

  const handleEmailChange = (event) => {
    setEmail(event.currentTarget.value);
  };

  return (
    <>
      <span className='footer__headings'>Newsletter</span>
      <NewsletterForm onSubmit={handleSubmit}>
        <NewsletterInput
          placeholder='Email address'
          name='email'
          type='text'
          onChange={handleEmailChange}
        />
        <div className='consent__wrap'>
          <p>
            ΜΕ ΤΗΝ ΕΓΓΡΑΦΗ ΣΑΣ, ΣΥΜΦΩΝΕΙΤΕ ΜΕ ΤΗΝ ΠΟΛΙΤΙΚΗ ΠΡΟΣΤΑΣΙΑΣ ΠΡΟΣΩΠΙΚΩΝ
            ΔΕΔΟΜΕΝΩΝ ΚΑΙ ΘΑ ΛΑΜΒΑΝΕΤΕ ΤΑ ΕΝΗΜΕΡΩΤΙΚΑ ΜΑΣ ΔΕΛΤΙΑ. ΜΠΟΡΕΙΤΕ ΝΑ
            ΑΝΑΚΑΛΕΣΕΤΕ ΤΗΝ ΣΥΓΚΑΤΕΘΕΣΗ ΣΑΣ, ΕΠΙΚΟΙΝΩΝΟΝΤΑΣ ΜΑΖΙ ΜΑΣ Ή ΚΑΝΟΝΤΑΣ
            ΚΛΙΚ ΣΤΟ "UNSUBSCRIBE" ΠΟΥ ΒΡΙΣΚΕΤΑΙ ΣΤΟ ΚΑΤΩ ΜΕΡΟΣ ΚΑΘΕ EMAIL ΜΑΣ.
          </p>
          <label
            className='consent__checkbox-label'
            htmlFor='newsletterconsent'
          >
            <input
              type='checkbox'
              name='newsletterconsent'
              id='newsletterconsent'
              required
            />
            <span>ΣΥΜΦΩΝΩ</span>
          </label>
        </div>
        {!isPending && (
          <button type='submit'>
            Εγγραφη <FaLongArrowAltRight />
          </button>
        )}
        {isPending && (
          <button type='submit' disabled>
            Εγγραφη...
          </button>
        )}
      </NewsletterForm>
      <ToastContainer position='bottom-right' />
    </>
  );
}

const NewsletterForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  button {
    border: 0.063rem solid #fff;
    padding: 1.25rem 1rem;
    color: #fff;
    cursor: pointer;
    width: 12.5rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
    font-weight: 700;
    text-transform: uppercase;
    transition-duration: 0.2s;
    svg {
      font-size: 1.25rem;
      transition-duration: 0.2s;
    }
    &:hover {
      color: #000;
      background-color: #fff;
      svg {
        transform: translateX(0.625rem);
      }
    }
  }
  div {
    border-top-color: #fff;
  }
`;

const NewsletterInput = styled.input`
  border: 0.063rem solid #fff;
  padding: 1.25rem 1rem;
  color: #000;
  width: 60%;
  min-width: 18.75rem;
  @media screen and (max-width: 960px) {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    min-width: 0;
  }
`;
