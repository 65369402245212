import React from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import styled from 'styled-components';

export default function SocialMedia() {
  return (
    <SocialMediaWrap>
      <span className='footer__headings'>Social Media</span>
      <SocialMediaIcons>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://www.facebook.com/OverQualityServices'
        >
          <FaFacebookF />
        </a>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://www.instagram.com/overqualitygr/'
        >
          <FaInstagram />
        </a>
      </SocialMediaIcons>
    </SocialMediaWrap>
  );
}

const SocialMediaWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7.688rem;
  padding: 1.313rem 0 1.313rem 0;
  border-top: 0.063rem solid #46da92;
  border-bottom: 0.063rem solid #46da92;
  width: 400px;
  @media screen and (max-width: 480px) {
    width: 100%;
    flex-direction: column;
    gap: 3rem;
  }
  .footer__headings {
    color: #46da92;
    text-transform: uppercase;
  }
`;

const SocialMediaIcons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.563rem;
  svg {
    color: #46da92;
    font-size: 2.375rem;
  }
`;
