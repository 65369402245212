import React from 'react';
import styled from 'styled-components';

export default function ContactInfo() {
  return (
    <ContactInfoList>
      <li>
        Τηλέφωνο: <a href='tel:6942497305'>69 424 973 05</a>
      </li>
      <li>Διεύθυνση: Δωδώνης 4, Μαρούσι</li>
      <li>
        Email: <a href='mailto:info@overquality'>info@overquality</a>
      </li>
    </ContactInfoList>
  );
}

const ContactInfoList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 1rem;
  font-weight: 700;
  li {
    a {
      text-decoration: none;
      color: #fff;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
