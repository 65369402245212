import { useStaticQuery, graphql, Link as LinkG } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

export default function Copyright() {
  const data = useStaticQuery(graphql`
    query Copyright {
      site {
        siteMetadata {
          privacyPolicyLink
          copyright
        }
      }
    }
  `);
  const { copyright, privacyPolicyLink } = data.site.siteMetadata;
  return (
    <CopyrightWrap>
      {copyright}
      <LinkG to={privacyPolicyLink}>
        Όροι χρήσης & Προυποθέσεις - Πολιτική Απορρήτου
      </LinkG>
    </CopyrightWrap>
  );
}

const CopyrightWrap = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.9375rem;
  justify-content: center;
  align-items: center;
  border-top: 0.063rem solid #fff;
  padding-top: 1.75rem;
  gap: 0.25rem;
  font-weight: 200;
  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
  a {
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }
`;
